
import * as React from "react"
import tw from 'twin.macro';

const Catalogo = ({ color, bgColor, hover }) => {
  return (
    <div
      css={[tw`inline-block`, hover && tw`opacity-60`]}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={48}
        height={38}
        fill={bgColor}
        stroke={color}
      >
        <path
          strokeMiterlimit={10}
          d="M.5.5h31v21H.5V.5Z"
          opacity={0.14}
        />
        <path
          strokeMiterlimit={10}
          d="M4.5 4.5h31v21h-31v-21Z"
          opacity={0.34}
        />
        <path
          strokeMiterlimit={10}
          d="M8.5 8.5h31v21h-31v-21Z"
          opacity={0.54}
        />
        <path
          strokeMiterlimit={10}
          d="M12.5 12.5h31v21h-31v-21Z"
          opacity={0.74}
        />
        <path
          strokeMiterlimit={10}
          d="M16.5 16.5h31v21h-31v-21Z"
          opacity={0.94}
        />
      </svg>
    </div>
  )
}

export default Catalogo
